<template>
    <div class="">
        <section class="content">
            <div class="profile-header profile-bg">
                <div class="row">
                    <div class="col-md-2 text-right pl-5 pr-0 mr-0">
                        <img style="height:100%;width:100%;" :src="getClubLogoImg()" alt="User Avatar" class="mr-3 ">
                    </div>
                    <div class="col-md-6 pl-5">
                        <div class="profiler-detail">
                            <h3>{{ memberInfoData.full_name }}</h3>
                            <h5>{{ memberInfoData.reg_type_name }} Membership</h5>
                            <div class="b-der"></div>
                            <div class="d-flex">
                                <div class="reg_date mt-3 mr-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                            <img src="/dist/img/profile/ico-registerd-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span class="lable">Registered Date</span>
                                            <span> {{ usdate(memberInfoData.membership_startdate) || "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="ex_date mt-3 ml-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                             <img src="/dist/img/profile/ico-expired-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span  class="lable">Expiration Date</span>
                                            <span> {{ memberInfoData.isLifetime != undefined && memberInfoData.isLifetime == 1 ? 'N/A' : usdate(memberInfoData.membership_endtate)}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid profile-body">
                <div class="row profile-body" id="travelinformation" v-if="this.getSiteProps('general.travelInformation')">
                    <div v-if="isTravelFormloading==true" class="d-flex justify-content-center userpf-loader travel-form-loader">
                        <div  class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                  <div class="col-md-12">
                    <div class="card table-head Merchandise mb-4">
                      <div class="card-header">
                        <div class="header-title" style="width: 100%">
                          <div class="row">
                            <div class="col-md-10">
                              <h3>Travel Information</h3>
                            </div>
                            <div class="col-md-2 text-right pt-1" v-if="this.addTravelForm">
                              <button class="btn btn-primary btn-blue btn-sm" @click="addTravelInformation()"><i class="fa fa-plus"></i> Add Travel Information</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-0 table-responsive">
                        
                        <b-table
                           id="myMerchandiseTable"
                           :items="travelInformation"
                           :fields="travelInformationTbl"
                           class="mt-3"
                           hover
                           show-empty
                        >
                         <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                  
                               <button
                                class="btn btn-primary ml-1"
                                @click="editTravelInformation(row.item.id)"
                              >
                                 <i class="far fa-edit"></i>
                              </button>
                            </div>
                          </div>
                        </template>
                       </b-table>
                     </div>
                   </div>
                 </div>
               </div>
            </div>
        </section>
    </div>
</template>

<script>

import moment from "moment";
import $ from 'jquery';
 import axios from 'axios';
import DatePick from 'vue-date-pick';

export default {
    name: 'Travel Information',
    components: {

    },
    data(){
        return {
            travelData: '',
            memberInfoData: '',
            addTravelForm: false,
            travelInformation: '',
            travelInformationTbl:[
                {key: 'Travel Information' , formatter: () => {
                    return 'USA Shooting Travel Information';
                    },
                },
                {key: 'created_at', label: 'Created Date', sortable: false,
                    formatter: (value) => {
                    return this.formatDate(value) || "N/A";
                    },
                },
                {key: 'updated_at', label: 'Updated Date', sortable: false,
                    formatter: (value) => {
                    return this.formatDate(value) || "N/A";
                    },
                },
                {key: "actions", sortable: false }
            ],
            isTravelFormloading : true,
        }
    },
    
    methods: {
        formatDate(value)
        {
            var date = moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },

        getClubLogoImg(){
            return (this.memberInfoData.profile_pic!=null? process.env.VUE_APP_URL+this.memberInfoData.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');
        },
        loadTravelInformation(){
        axios.get(this.basePath+"api/member/travel-information/list",{ headers:this.memberHeaders }).then(response => {
            console.log(response);
            this.travelInformation = response.data.data;
            if (this.travelInformation.length === 0){
                this.addTravelForm = true;
            }else{
                this.addTravelForm = false;
            }
            this.isTravelFormloading = false;
        })
        .catch(err => {
            this.isTravelFormloading = false;
            console.log(err);
        })
        },
        addTravelInformation(){
            var vueRouter = this.$router;
            vueRouter.push(`/member/travel-information/add`);
        },
        editTravelInformation(memberId){
            var vueRouter = this.$router;
            vueRouter.push(`/member/travel-information/${memberId}/edit`);
        },
    },

    mounted() {
        this.memberInfoData = JSON.parse(localStorage.getItem('member'));
        this.loadTravelInformation();     
    }
}
</script>

<style>

</style>